<template>
  <el-container v-if="isOK">
    <div
      v-if="!this.$store.getters.openSlider && this.$store.getters.isPCM"
      class="drawer-bg"
      @click="changeOpenSlider(true)"
    />
    <el-aside
      :class="
        this.$store.getters.isPCM
          ? this.$store.getters.openSlider
            ? 'closeAside PcMAside'
            : 'PcMAside'
          : ''
      "
      width="201px"
    >
      <slider />
    </el-aside>

    <el-container>
      <el-header>
        <el-button
          type="primary"
          icon="el-icon-s-unfold"
          @click="changeOpenSlider(false)"
          class="hidden-sm-and-up"
        ></el-button>

        <div class="rightMe">
          <el-link type="primary" href="/">首页</el-link>
          <el-dropdown trigger="click" @command="logout">
            <span class="el-dropdown-link">
              <el-avatar
                :size="40"
                style="vertical-align: middle"
                :src="'https://q.qlogo.cn/headimg_dl?dst_uin=1967591590&spec=640&img_type=jpg'"
              ></el-avatar>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>注销</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>

      <el-main>
        <transition name="el-fade-in" mode="out-in">
          <router-view :key="this.$route.path" />
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import "./../../assets/css/admin.css";
import "./../../assets/mouth.js";
import Slider from "./silder.vue";

export default {
  name: "Main",
  data() {
    return {
      breadcrumb: {},
      isOK: false, //auth 通过 则渲染组件否则重定向到login
    };
  },
  components: {
    Slider,
  },
  async beforeCreate() {
    // localStorage.removeItem("Authorization");
    let that = this;
    if (
      (localStorage.getItem("Authorization") === null ||
        localStorage.getItem("Authorization") === undefined) &&
      this.$route.fullPath !== "/admin/login"
    ) {
      return this.$router.replace("/admin/login");
    }
    await this.$axios
      .get("/adminApi/auth")
      .then(function (resp) {
        if (resp.data.code !== 200) {
          that.$notify.error({
            title: resp.data.msg,
            message: resp.data.data,
            duration: 10000,
          });
          return that.$router.replace("/admin/login");
        }
      });
    //通知 组件 开始渲染
    this.isOK = true;
  },
  watch: {
    $route() {
      //点击导航菜单后关闭
      if (this.$store.getters.isPCM) {
        this.changeOpenSlider(true);
      }
    },
  },
  methods: {
    changeOpenSlider(t) {
      this.$store.commit("openSlider", t);
    },
    logout(){
      localStorage.removeItem("Authorization");
      this.$router.replace("/admin/login");
    }
  },
};
</script>

<style scoped>
.el-container {
  height: 100%;
}

.el-header {
  display: block;
  /* background-color: #20222a; */
  color: #333;
  line-height: 60px;
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
}
.el-dropdown-link {
  cursor: pointer;
  margin: 0 20px 0 20px;
}
.rightMe {
  display: inline-flex;
  height: 100%;
  float: right;
}

/* .el-main div{
  margin: 0 auto;
  max-width: 850px;
} */
.el-aside {
  background-color: #20222a;
  color: #333;
  line-height: 200px;
  transition: all 0.28s;
  z-index: 10003;
}
.el-aside::-webkit-scrollbar {
  display: none;
}

.closeAside {
  width: 0 !important;
}

.PcMAside {
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
}

.drawer-bg {
  background: #000;
  opacity: 0.6;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 10002;
}

/* .component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
 {
  opacity: 0;
} */

.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 60px;
}
</style>
