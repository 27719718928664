<template>
  <div style="margin-top: 60px">
    <!--  -->
    <el-menu
      :default-active="this.$route.path"
      class="el-menu-vertical-demo"
      background-color="#20222A"
      text-color="#fff"
      active-text-color="#ffd04b"
      :router="true"
      :collapse="this.$store.getters.openSlider"
      :collapse-transition="false"
    >
      <el-menu-item index="/admin/index">
        <i class="el-icon-menu"></i>
        <span slot="title">首页</span>
      </el-menu-item>

      <el-menu-item index="/admin/visitors">
        <i class="el-icon-menu"></i>
        <span slot="title">当前在线</span>
      </el-menu-item>

      <el-menu-item index="/admin/blackVisitors">
        <i class="el-icon-menu"></i>
        <span slot="title">黑名单</span>
      </el-menu-item>

      <el-menu-item index="/admin/historyRequest">
        <i class="el-icon-menu"></i>
        <span slot="title">历史访问</span>
      </el-menu-item>

      <el-menu-item index="/admin/newArticle">
        <i class="el-icon-menu"></i>
        <span slot="title">文章发表</span>
      </el-menu-item>

      <el-menu-item index="/admin/editArticle">
        <i class="el-icon-menu"></i>
        <span slot="title">文章编辑</span>
      </el-menu-item>

      <el-menu-item index="/admin/comment">
        <i class="el-icon-menu"></i>
        <span slot="title">留言板</span>
      </el-menu-item>

      <el-submenu index="/me">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span slot="title">me</span>
        </template>
        <el-menu-item index="/admin/me/main">
          <i class="el-icon-menu"></i>
          <span slot="title">main</span>
        </el-menu-item>
        <el-menu-item index="/admin/me/show">
          <i class="el-icon-menu"></i>
          <span slot="title">show</span>
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
};
</script>

<style scoped>
.el-menu {
  border: none;
}
</style>